
















































































































































































































































































































































































































import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";

@Component
export default class TerminosDirectoComponent extends Vue {}
